import React from "react";

import {SvgIcon} from "../../components/SvgIcon";
import {IIcon} from "../../interfaces/IIcon";

export const PlusIcon = (props: IIcon) => {
    return (
        <SvgIcon {...props}>
            <path d="M7.916 5.417h-2.5v2.5h-.833v-2.5h-2.5v-.833h2.5v-2.5h.833v2.5h2.5v.833Z" />
        </SvgIcon>
    );
};
